body {
  margin: 0;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.load-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(3, 154, 255);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.one .loader,
.one .loader:before,
.one .loader:after {
  background-color: #fff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.one .loader:before,
.one .loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.one .loader:before {
  left: -1.5em;
}
.one .loader {
  font-size: 10px;
  text-indent: -9999em;
  margin: 4.3em auto;
  position: relative;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.one .loader:after {
  left: 1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0 #fff;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em #fff;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0 #fff;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em #fff;
    height: 5em;
  }
}
